<template>
  <div>
    <b-card class="mb-2">
      <h2>Ajouter un commissariat</h2>
    </b-card>
    <div>
      <!-- form -->
      <b-form
        class="auth-register-form mt-2"
        @submit.prevent="()=>{}"
      >
        <!-- Section - Informations sur le commissariat -->
        <FormCreateOrEditCommissariat
          ref="formCreateOrEditCommissariatRef"
          v-model="form"
        />

        <div
          v-if="false"
          class="mb-1"
        >
          <!-- Styled -->
          <RecaptchaWidget @onRecaptchaChange="onRecaptchaChange" />
          <small
            v-if="$v.recaptchaResponse.isValid.$invalid"
            class="text-danger"
          >Veuillez résoudre le recaptcha pour continuer</small>
        </div>

        <!-- submit button -->
        <b-button
          variant="primary"
          block
          type="submit"
          @click="submitForm"
        >
          <b-spinner
            v-if="isLoading"
            small
          />
          Inscrire
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVuelidate from '@vuelidate/core'
import { required, email as emailValidator } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import AppLogo from '@/components/AppLogo.vue'
import RecaptchaWidget from '@/components/RecaptchaWidget.vue'
import localstorageService from '@/services/localstorage/localstorage.service'
import DepartementCommuneArrondissementVillageForm from '@/components/DepartementCommuneArrondissementVillageForm.vue'

// store module and vuex utilities
import commissaireStroreModule from '@/store/commissaire'
import rolesStroreModule from '@/store/roles'
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
import FormCreateOrEditCommissariat from './components/FormCreateOrEditCommissariat.vue'

export default {
  components: {
    FormCreateOrEditCommissariat,
    // BSV
    BRow,
    BCol,

    BCard,
    BCardHeader,
    BCardBody,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    // validations
    AppLogo,
    vSelect,
    RecaptchaWidget,
    BSpinner,
    ToastificationContent,
    DepartementCommuneArrondissementVillageForm,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'commissaire', module: commissaireStroreModule },
      { path: 'roles', module: rolesStroreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules, $v: useVuelidate({ $lazy: true }) }
  },
  data() {
    return {
      departementFormProps: {
        canUpdateChildOnParentChanges: {
          departement: true,
          commune: true,
        },
        departementFormLabels: {
          departement: 'Département',
          commune: 'Commune',
          arrondissement: 'Arrondissement',

        },
        breakPoints: { cols: { lg: '12' } },
      },
      triggerDepartementFormValidations: false,

      form: {
        nom: null,
        departement: null,
        arrondissement: null,
        commune: null,
        adresse: null,
        telephone: null,
        email: null,
        // password: null,
      },
      // confPassword: null,

      currentUserId: localstorageService.getUserId(),
      currentRole: null,

      // recaptchaResponse: null,
      passwordFieldType: 'password',
      isLoading: false,
      listEtablissements: [],
    }
  },
  computed: {},
  watch: {},
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },

  validations() {
    return {
      form: {
        nom: { required },
        departement: { required },
        arrondissement: { required },
        adresse: { required },
        // telephone: { required },
        commune: { required },
        // password: { required },
        email: { required, emailValidator },
      },
      // confPassword: { required },
      // recaptchaResponse: { isValid: val => val && val.type === 'success' },
      // isLoading: false,
    }
  },
  methods: {
    ...mapActions('commissaire', {
      createCommissaire: 'createCommissaire',
    }),
    ...mapActions('roles', { fetchRoles: 'fetchRoles' }),
    ...mapActions('imagePuzzleSliderCaptcha', { showSliderCaptcha: 'show' }),
    // -----------------------------------------------------------------------
    onDepartementFormChange(val) {
      this.form = { ...this.form, ...val }
    },
    togglePasswordInputType() {
      // eslint-disable-next-line no-unused-expressions
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    onRecaptchaChange(recaptchaEvent) {
      if (recaptchaEvent && recaptchaEvent.type === 'success') {
        this.recaptchaResponse = recaptchaEvent
      } else {
        this.recaptchaResponse = null
      }
    },
    async submitForm() {
      const formRef = this.$refs.formCreateOrEditCommissariatRef
      await formRef.submitForm()
      if (this.isLoading) return
      if (formRef.$v.form.$invalid) {
        return
      }
      this.isLoading = true
      const isNotNull = val => val !==null && val.trim() !== ''
      let form = Object.keys(this.form).reduce((acc, val) => {
        console.log(val, isNotNull(this.form[val]))
        if (isNotNull(this.form[val])) {
          return { ...acc, [val]: this.form[val] }
        }
        return acc
      }, {})
      // console.log('form :>> ', this.form)
      // console.log('form :>> ', form)
      // return
      // const form = { ...this.form }

      // Execute reCAPTCHA with action "submit".
      const token = await this.$recaptcha('submit')
      if (!token) {
        this.isLoading = false
        return
      }
      // console.log('token: ', token);
      form.token = token
      delete form.confirmPassword

      this.createCommissaire(form)
        .then(result => {
          this.isLoading = false
          this.$router.push({ name: 'commissariats-list' })
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Inscription commissaire réussie',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getRoleCommissaire() {
      const data = { options: { code: 'commissaire' } }
      this.fetchRoles(data)
        .then(res => {
          if (res.data && res.data.resources[0]) {
            this.form.role = res.data.resources[0]._id
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },

  mounted() {
    this.getRoleCommissaire()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
